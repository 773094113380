<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            3. Первая помощь при отравлении продуктами сгорания
          </v-card-title>
        </v-col>
        <v-col
          cols="12"
          class="pb-0"
        >
            <v-card
              class="d-flex title font-weight-light pa-7"
            >
            <v-img
              lazy-src="img/material/poison.svg"
              width="10%"
              contain
              src="img/material/poison.svg"
              class="mr-10"
            ></v-img>
            <div>
              <p>Один из самых опасных факторов пожара – ядовитый угарный газ.</p>
              <p class="pb-0 mb-0">Он не имеет запаха и невидим. Попадая в организм человека через органы дыхания, он блокирует поступление кислорода. Летальный исход может наступить в течение короткого времени.</p>
            </div>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card-title>
            Симптомы:
          </v-card-title>
          <div class="ptm-img-rel">
            <v-img
              lazy-src="img/material/woman-num.svg"
              width="60%"
              contain
              src="img/material/woman-num.svg"
              class="mr-10 ptm-img"
            ></v-img>
            <v-tooltip right color="red">
              <template v-slot:activator="{ on }">
                <v-btn dark v-on="on" rounded class="ptm-img-woman-item-1 title pa-5 red">1</v-btn>
              </template>
              <span class="pa-5 pl-0 subtitle-1">Слабость, боль в области лба и висков,<br> тяжесть в голове, головокружение, шум в ушах,<br> сонливость, потеря сознания</span>
            </v-tooltip>
            <v-tooltip right color="red">
              <template v-slot:activator="{ on }">
                <v-btn dark v-on="on" rounded class="ptm-img-woman-item-2 title pa-5 red">2</v-btn>
              </template>
              <span class="pa-5 pl-0 subtitle-1">Затруднение дыхания или усиленное сердцебиение</span>
            </v-tooltip>
            <v-tooltip right color="red">
              <template v-slot:activator="{ on }">
                <v-btn dark v-on="on" rounded class="ptm-img-woman-item-3 title pa-5 red">3</v-btn>
              </template>
              <span class="pa-5 pl-0 subtitle-1">Тошнота, понос, рвота, непроизвольное мочеиспускание</span>
            </v-tooltip>
            <v-tooltip right color="red">
              <template v-slot:activator="{ on }">
                <v-btn dark v-on="on" rounded class="ptm-img-woman-item-4 title pa-5 red">4</v-btn>
              </template>
              <span class="pa-5 pl-0 subtitle-1">Бледно-синюшная кожа</span>
            </v-tooltip>
            <v-tooltip right color="red">
              <template v-slot:activator="{ on }">
                <v-btn dark v-on="on" rounded class="ptm-img-woman-item-5 title pa-5 red">5</v-btn>
              </template>
              <span class="pa-5 pl-0 subtitle-1">Резкая мышечная боль, боль в груди и животе, судороги</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col cols="8">
          <v-card-title>
            Чтобы не отравиться самому:
          </v-card-title>
          <v-card-text class="title font-weight-light mb-5">
            Вход в рот или нос делайте через смоченную марлевую салфетку (носовой платок и др.), а при пассивном выходе пострадавшего отклоняйте свою голову в сторону, чтобы выдыхаемый газ не попал Вам в легкие.
          </v-card-text>
          <v-card flat>
            <v-tabs
            v-model="tab"
            grow
            color="red"
            slider-color="white"
            >
              <v-tab class="pa-5">
                <v-card-title>
                  <v-icon size="50">mdi-numeric-1-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="50">mdi-numeric-2-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="50">mdi-numeric-3-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="50">mdi-numeric-4-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="50">mdi-numeric-5-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="50">mdi-numeric-6-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
            </v-tabs>

            <v-tabs-items  v-model="tab">
              <v-tab-item >
                <v-card-text class="title font-weight-light mt-10">
                  Вынесите пострадавшего на свежий воздух
                </v-card-text>
              </v-tab-item>
              <v-tab-item >
                <v-card-text class="title font-weight-light mt-10">
                  Расстегните его одежду, восстановите проходимость дыхательных путей
                </v-card-text>
              </v-tab-item>
              <v-tab-item >
                <v-card-text class="title font-weight-light mt-10">
                  Уложите пострадавшего, приподняв ему ноги. Следите за проходимостью дыхательных путей (без западения языка)
                </v-card-text>
              </v-tab-item>
              <v-tab-item >
                <v-card-text class="title font-weight-light mt-10">
                  Вызовите "Скорую помощь"
                </v-card-text>
              </v-tab-item>
              <v-tab-item >
                <v-card-text class="title font-weight-light mt-10">
                  Разотрите тело и грудь, укройте потеплее
                </v-card-text>
              </v-tab-item>
              <v-tab-item >
                <v-card-text class="title font-weight-light mt-10">
                  При отсутствии сознания придайте устойчивое боковое положение
                </v-card-text>
                <v-card-text class="title font-weight-light">
                  При рвоте поверните голову в сторону, чтобы не дать задохнуться. Следите за отхождением рвотной массы
                </v-card-text>
                <v-card-text class="title font-weight-light">
                  При отсутствии дыхания приступите к проведению ИВЛ (искусственная вентиляция легких)
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
      <v-sheet flat class="d-flex justify-center mx-auto pb-0 mt-2">
        <v-card class="d-flex justify-center" flat>
          <v-icon color="red" size="30">mdi-cursor-pointer</v-icon>
           <v-icon color="red" size="30">mdi-cursor-default-click-outline</v-icon>
          <v-card-title class="font-weight-light">
            Наводите и нажимайте на кнопки для изучения информации
          </v-card-title>
        </v-card>
      </v-sheet>
    </v-container>
  </div>
</template>
<script>
export default {
  data: () => ({
    tab: null
  })
}
</script>
<style lang="less" scoped>
.ptm-img-rel {
  position: relative;
}
.ptm-img-woman-item-1 {
  position: absolute;
  top: -2%;
  right: 30%;
}
.ptm-img-woman-item-2 {
  position: absolute;
  top: 19%;
  right: 30%;
}
.ptm-img-woman-item-3 {
  position: absolute;
  top: 31%;
  right: 30%;
}
.ptm-img-woman-item-4 {
  position: absolute;
  top: 42%;
  right: 30%;
}
.ptm-img-woman-item-5 {
  position: absolute;
  top: 53%;
  right: 30%;
}
</style>
